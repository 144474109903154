import React, { useEffect, useState } from "react";
import axios from "axios";
import opsService from "../../../services/ops/opsService";
import Select from "react-select";

function UsernameSelector({
    customer_id,
    onSelect,
    isDisabled = false,
    defaultValue,
    billing_center_id,
    ...props
}) {
    const [inputValue, setInputValue] = useState(defaultValue);
    const [suggestions, setSuggestions] = useState([]);
    const [isFocus, setIsFocus] = useState(false);


    useEffect(() => {
        setInputValue(defaultValue)
    }, [])

    useEffect(() => {
        // Fetch and set default suggestions when the component mounts
        async function fetchDefaultSuggestions() {
            try {
                let option = {
                    customer_id: customer_id,
                    billing_center_id: billing_center_id
                }
                const response = await opsService.allUsername(option)


                // Assuming your response.data is an array of strings
                if (Array.isArray(response.data.data)) {
                    setSuggestions(
                        response.data.data.map((item) => ({
                            value: item.name,
                            label: item.name,
                        }))
                    );
                } else {
                    console.error("Invalid data format:", response.data);
                    setSuggestions([]); // Clear suggestions if data format is invalid
                }
            } catch (error) {
                console.error("Error fetching default suggestions:", error);
            }
        }

        fetchDefaultSuggestions();
    }, [billing_center_id, customer_id]); // Empty dependency array ensures this effect runs once after mounting

    const handleChange = async (event) => {
        const value = event.target.value;
        setInputValue(value);
        try {
            let option = {
                customer_id: customer_id,
                billing_center_id: billing_center_id
            }
            const response = await opsService.allUsername(option)

            // Assuming your response.data is an array of strings
            if (Array.isArray(response.data.data)) {
                setSuggestions(
                    response.data.data.map((item) => ({
                        value: item.name,
                        label: item.name,
                    }))
                );
            } else {
                console.error("Invalid data format:", response.data);
                setSuggestions([]); // Clear suggestions if data format is invalid
            }
        } catch (error) {
            console.error("Error fetching suggestions:", error);
            setSuggestions([]); // Clear suggestions in case of an error
        }
    };

    return (
        <div className="relative">
            <input
                type={'text'}
                className={`${isDisabled ? "bg-gray-50" : "bg-white"
                    // } border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-4 p-2.5      `}
                    } w-full  bg-white border border-gray-500 rounded-lg outline-none form-control`}
                placeholder={"Nom d'utilisateur*".toUpperCase()}
                value={inputValue}
                onChange={(e) => {
                    setIsFocus(true);
                    console.log('la famille ', e.target.value)
                    onSelect(e.target.value);
                    handleChange(e);
                }}
                onFocus={() => {
                    setIsFocus(true);
                }}
                onBlur={() => {
                    setTimeout(() => {
                        setIsFocus(false);
                    }, 300);
                }}
                disabled={isDisabled}
            />
            {isFocus && (
                <div className="absolute left-0 right-0 bg-white mt-2 border max-h-60 rounded-sm overflow-scroll z-10">
                    {/* {suggestions.length > 0 ? (
                        <> */}
                    {suggestions.map((opt, i) => (
                        <div
                            onClick={() => {
                                setInputValue(opt.label);
                                onSelect(opt.label);
                            }}
                            className="px-4 py-2 cursor-pointer text-sm"
                            key={i}
                        >
                            {opt.label}
                        </div>
                    ))}
                    {/* </>
                    ) : (
                        <div className="text-center">
                            {" "}
                            <span className="px-4 py-2 cursor-pointer text-sm">
                                Votre liste est vide
                            </span>
                        </div>
                    )} */}
                </div>
            )}
        </div>
    );
}

export default UsernameSelector;




export const UsernameSelector2 = ({
    customer_id,
    onSelect,
    isDisabled = false,
    defaultValue,
    billing_center_id,
    ...props
}) => {
    const [inputValue, setInputValue] = useState(defaultValue);
    const [suggestions, setSuggestions] = useState([]);
    const [isFocus, setIsFocus] = useState(false);


    useEffect(() => {
        setInputValue(defaultValue)
    }, [defaultValue])

    useEffect(() => {
        // Fetch and set default suggestions when the component mounts
        async function fetchDefaultSuggestions() {
            try {
                let option = {
                    customer_id: customer_id,
                    billing_center_id: billing_center_id
                }
                const response = await opsService.allUsername(option)

                console.log('response', response)
                // Assuming your response.data is an array of strings
                if (Array.isArray(response.data.data)) {
                    setSuggestions(
                        response.data.data.map((item) => ({
                            value: item.id,
                            label: item.name,
                        }))
                    );
                } else {
                    console.error("Invalid data format:", response.data);
                    setSuggestions([]); // Clear suggestions if data format is invalid
                }
            } catch (error) {
                console.error("Error fetching default suggestions:", error);
            }
        }

        fetchDefaultSuggestions();
    }, [customer_id, billing_center_id]); // Empty dependency array ensures this effect runs once after mounting



    return (
        <Select
            placeholder={`Choix du  nom d'utilisateur*`.toUpperCase()}
            options={suggestions}
            value={defaultValue}
            onChange={(v) => {
                onSelect(v);
            }}
            isDisabled={isDisabled}
            className="text-base"
            {...props}
        />
    )
}


export const UsernameSelect = ({
    customer_id,
    onSelect,
    isDisabled = false,
    defaultValue,
    billing_center_id,
    ...props
}) => {
    const [inputValue, setInputValue] = useState(defaultValue);
    const [suggestions, setSuggestions] = useState([]);
    const [isFocus, setIsFocus] = useState(false);


    useEffect(() => {
        setInputValue(defaultValue)
    }, [defaultValue])

    useEffect(() => {
        // Fetch and set default suggestions when the component mounts
        async function fetchDefaultSuggestions() {
            try {
                let option = {
                    customer_id: customer_id,
                    billing_center_id: billing_center_id
                }
                const response = await opsService.allUsername(option)


                // Assuming your response.data is an array of strings
                if (Array.isArray(response.data.data)) {
                    setSuggestions(
                        response.data.data.map((item) => ({
                            value: item.name,
                            label: item.name,
                        }))
                    );
                } else {
                    console.error("Invalid data format:", response.data);
                    setSuggestions([]); // Clear suggestions if data format is invalid
                }
            } catch (error) {
                console.error("Error fetching default suggestions:", error);
            }
        }

        fetchDefaultSuggestions();
    }, [billing_center_id, customer_id]); // Empty dependency array ensures this effect runs once after mounting

    const handleChange = async (event) => {
        const value = event.target.value;
        setInputValue(value);
        try {
            let option = {
                customer_id: customer_id,
                billing_center_id: billing_center_id
            }
            const response = await opsService.allUsername(option)

            // Assuming your response.data is an array of strings
            if (Array.isArray(response.data.data)) {
                setSuggestions(
                    response.data.data.map((item) => ({
                        value: item.name,
                        label: item.name,
                    }))
                );
            } else {
                console.error("Invalid data format:", response.data);
                setSuggestions([]); // Clear suggestions if data format is invalid
            }
        } catch (error) {
            console.error("Error fetching suggestions:", error);
            setSuggestions([]); // Clear suggestions in case of an error
        }
    };

    const value = suggestions.find(s => s.value === defaultValue)

    return (
        <Select
            placeholder={`Choix du  nom d'utilisateur*`.toUpperCase()}
            options={suggestions}
            value={value}
            onChange={(v) => {
                //console.log("VV", v)
                onSelect(v);
                setInputValue(v);
            }}
            isDisabled={isDisabled}
            className="text-base"
            {...props}
        />
    );
}