import React, { useEffect, useState } from 'react';
import TexteareaInput from '../Utils/UI/TexteareaInput';
import { TbCheck, TbPencil } from 'react-icons/tb';
import { exnetSpaceColor, urlSplit } from '../../utils/Functions/otherFunctions';
import { toast } from 'react-toastify';
import opsService from '../../services/ops/opsService';

function CommentaireComponent({ rows = 1, withLabel = false, editable = true, value, setExpeditionData = false }) {
    const [disable, setDisable] = useState(true)
    const { exnetSpace } = urlSplit()
    const [commentaire, setCommentaire] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)

    
    const handleSubmit = async () => {
        setDisable(true)
        try {

            const submitData = {
                content: commentaire,
                shipping_id: value.id,
            }

            setIsSubmitting(true);


            const request = opsService.addComment
            const { data } = await request(submitData);
            if (data.success) {
                let commentsList = value?.comments || [];
                console.log(commentsList)

                let newComments = { content: commentaire, shipping_id: value?.id, author_id: value?.user_id }

                if (setExpeditionData) {
                    setExpeditionData(d => ({ ...d, comments: [...commentsList, newComments] }));
                }

                toast.success(`Commentaire ajouté avec succès!`, {
                    position: "bottom-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            }
        } catch (error) {
            console.log(error)
            toast.error(`Commentaire non ajouté avec succès!`, {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        } finally {
            setIsSubmitting(false)
        }
    };



    useEffect(() => {

        console.log('hello la team', value)
        if (value) {
            let newValue = value?.comments;
            if (newValue?.length > 0) {
                // let { content } = newValue[newValue?.length - 1]
                let { content } = newValue[0]
                setCommentaire(content)
            }

        }
    }, [value])

    return (
        <>


            <div className='flex items-center gap-3'>
                {withLabel && <label className="text-base font-semibold text-[#35446f] uppercase">Commentaire</label>}
                {editable &&
                    <span onClick={() => setDisable(!disable)}>
                        <TbPencil size={18} className='pointer' />
                    </span>
                }
                {!disable && <>
                    <span style={{ backgroundColor: exnetSpaceColor(exnetSpace) }} className={'rounded-md'}>

                        <TbCheck size={18} className='pointer text-[#35446f]'
                            disabled={isSubmitting} onClick={handleSubmit} />
                    </span>


                </>}
            </div>

            <TexteareaInput
                rows={rows}
                // className="w-full bg-white border border-gray-500 rounded-lg outline-none "
                id="text"
                value={commentaire}
                onChange={(e) => {
                    setCommentaire(e.target.value)
                }}
                disabled={disable}
            />


        </>
    );
}

export default CommentaireComponent;